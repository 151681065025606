<template>
  <v-content>
    <v-container fluid grid-list-lg>
      <v-layout wrap justify-center row fill-height>
        <v-flex xs12>
          <AppTitle title="Inventarios" subtitle="Listado de inventarios realizados"></AppTitle>
        </v-flex>
        <v-flex xs12 sm12>
          <v-card>
            <template>
              <v-data-table
                :headers="headers"
                :items="inventories"
                :loading="loading"
                :pagination.sync="pagination"
                :rows-per-page-items="rowsPerPage"
                class="elevation-0"
              >
                <v-progress-linear slot="progress" color="accent" indeterminate></v-progress-linear>
                <template slot="items" slot-scope="props">
                  <tr @click="openDetail(props.item.id)">
                    <td class="text-xs-right" v-text="props.item.id"></td>
                    <td class="text-xs-left" v-text="props.item.description"></td>
                    <td class="text-xs-left" v-text="props.item.user_name"></td>
                    <td class="text-xs-right">
                      <v-chip v-if="props.item.state === 0" color="warning">
                        No enviado
                      </v-chip>
                      <v-chip v-if="props.item.state === 1" text-color="white" color="error">
                        Finalizado
                      </v-chip>
                      <v-chip v-if="props.item.state === 2" text-color="white" color="success">
                        Inventario Ajustado
                      </v-chip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </template>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import { INVENTORIES } from '@/config'
import AppTitle from '../useful/title'

export default {
  name: 'Inventory',
  components: { AppTitle },
  data() {
    return {
      title: 'Inventarios realizados',
      inventories: [],
      movements: [],
      loading: false,
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      headers: [
        {
          text: 'ID',
          align: 'right',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Nombre',
          align: 'left',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Usuario',
          align: 'left',
          sortable: false,
          value: 'user'
        },
        {
          text: 'Estado',
          align: 'right',
          sortable: false,
          value: 'active'
        }
      ]
    }
  },
  watch: {},
  mounted() {
    this.getInventory()
    this.filterFrom = this.$moment()
      .subtract(1, 'months')
      .format('YYYY-MM-DD')
    this.filterEnd = this.$moment().format('YYYY-MM-DD')
    this.$store.dispatch('updateTitle', 'Inventarios')
  },
  methods: {
    async getInventory() {
      try {
        this.loading = true
        const res = await this.$http.get(INVENTORIES)
        this.inventories = res.data
        this.loading = false
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    openDetail(index) {
      this.$router.push({ name: 'InventoryDetail', params: { id: index } })
    }
  }
}
</script>
